import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { Header, SEO, SliceZone } from '../components'
import website from '../../config/website'
import ListingCard from '../components/ListingCard'
import Wrapper from '../components/Wrapper'
import Pagination from '../components/Pagination/Pagination'
// import ListingCat from '../components/ListingCat'

const PagePostsTemplate = ({ data, location, pageContext, prismicBlog }) => {
  const posts = data.allPrismicPost.edges
  const cats = data.allPrismicCategory.edges
  const page = data.prismicBlog
  const Featured = ({ humanPageNumber }) => {
    const isFirstPage = humanPageNumber === 1
    if (isFirstPage) {
      return (
        <>
          <SliceZone className="blog" allSlices={page.data.body} posts={posts} cats={cats} />
        </>
      )
    }
    // return <ListingCat cats={cats} />
    return null
  }

  return (
    <Layout location={location}>
      <SEO
        title={`${page.data.main_title.text} | ${website.title}`}
        pathname={location.pathname}
        desc={page.data.introduction.text}
        node={prismicBlog}
        // article
      />
      <Header light />

      <Featured {...pageContext} />
      <Wrapper>
        {posts && Array.isArray(posts) && posts.length > 0 && <ListingCard posts={posts} />}
        <Pagination pageContext={pageContext} />
      </Wrapper>
    </Layout>
  )
}

PagePostsTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default PagePostsTemplate

export const pagePostsQuery = graphql`
    query($skip: Int!, $limit: Int!) {
        allPrismicPost(
            sort: { fields: first_publication_date, order: DESC }
            skip: $skip
            limit: $limit
            filter: { uid: { ne: "dummy" } }
        ) {
            edges {
                node {
                    uid
                    first_publication_date
                    last_publication_date
                    data {
                        title {
                            text
                        }
                        shortdesc {
                            text
                        }
                        main {
                            full {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 400, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                        date(formatString: "DD.MM.YYYY")
                        categorie {
                            document {
                                uid
                                data {
                                    name
                                    color
                                }
                            }
                        }
                        authors {
                            author {
                                id
                                uid
                                document {
                                    data {
                                        name
                                        image {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicCategory(filter: { uid: { ne: "dummy" } }, sort: { fields: [data___name], order: ASC }) {
            edges {
                node {
                    data {
                        body {
                            id
                        }
                        color
                        name
                    }
                    uid
                }
            }
        }
        prismicBlog {
            uid
            data {
                main_title {
                    text
                    html
                }
                introduction {
                    html
                    text
                }
                body {
                    ... on PrismicBlogBodyHeroblog {
                        slice_type
                        id
                        primary {
                            maintitle {
                                html
                                text
                            }
                            subtitle {
                                html
                                text
                            }
                            introduction {
                                html
                                text
                            }
                            bgimg {
                                url
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1960, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                            bgcolor
                            orientation
                            theme
                        }
                    }
#                    ... on PrismicBlogBodyMiseEnAvantBlog {
#                        slice_type
#                        id
#                        primary {
#                            featured {
#                                url
#                                document {
#                                    uid
#                                    first_publication_date
#                                    last_publication_date
#                                    data {
#                                        title {
#                                            html
#                                            text
#                                        }
#                                        main {
#                                            localFile {
#                                                childImageSharp {
#                                                    fluid(maxWidth: 900, quality: 90) {
#                                                        ...GatsbyImageSharpFluid_withWebp
#                                                    }
#                                                }
#                                            }
#                                        }
#                                        shortdesc {
#                                            html
#                                            text
#                                        }
#                                        categorie {
#                                            uid
#                                            document {
#                                                uid
#                                                data {
#                                                    name
#                                                    color
#                                                }
#                                            }
#                                        }
#                                        authors {
#                                            author {
#                                                document {
#                                                    uid
#                                                    data {
#                                                        name
#                                                    }
#                                                }
#                                            }
#                                        }
#                                    }
#                                }
#                            }
#                            featuredtitle {
#                                html
#                            }
#                            featuredintro {
#                                html
#                            }
#                        }
#                    }
#                    ... on PrismicBlogBodyMenuCategories {
#                        id
#                        slice_type
#                        primary {
#                            categories {
#                                html
#                            }
#                        }
#                    }
                }
            }
        }
    }
`
